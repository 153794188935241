import React from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactChild;
  theme?: 'primary' | 'secondary';
  label?: string;
}

type ButtonThemeMap = {
  primary: string;
  secondary: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  theme = 'primary',
  className = '',
  disabled,
}) => {
  const themeClasses: ButtonThemeMap = {
    primary:
      'border border-indigo-dye bg-indigo-dye text-white hover:bg-royal-blue',
    secondary:
      'border border-white text-white hover:bg-indigo-dye hover:text-white hover:border-indigo-dye',
  };

  const disabledClasses = 'cursor-not-allowed opacity-50';

  return (
    <button
      disabled={disabled}
      className={`inline-block box py-3 px-8 rounded transition-all duration-300 focus:shadow-outline focus:outline-none text-sm font-bold ${
        themeClasses[theme]
      } ${disabled && disabledClasses} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
