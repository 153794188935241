import React from 'react';
import 'typeface-open-sans';
import Header from './header';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from 'react-scroll';

export interface LayoutProps {
  title?: string;
  description?: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // const isProd = process.env.NODE_ENV === 'production';

  return (
    <div className="font-body flex flex-col justify-between min-h-screen">
      <Header />
      <div className="pt-16 h-full flex-1 flex flex-col">{children}</div>
      <footer className="bg-royal-blue text-white py-3">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="flex flex-col md:flex-row">
            <div className="px-6 mb-6">
              <h1 className="text-2xl font-bold mb-3">Dewebgineer Solutions</h1>
              <p>Quality software solutions for your business</p>
            </div>
            <div className="px-6 mb-6">
              <h1 className="text-xl">Quick Links</h1>
              <ul className="flex flex-col">
                <li>
                  <Link
                    href="#"
                    to="services"
                    smooth={true}
                    className="block py-3 hover:text-zomp"
                    spy={true}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    to="about"
                    smooth={true}
                    className="block py-3 hover:text-zomp"
                    spy={true}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    to="portfolio"
                    smooth={true}
                    className="block py-3 hover:text-zomp"
                    spy={true}
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <GatsbyLink to="/blog" className="block py-3 hover:text-zomp">
                    Blog
                  </GatsbyLink>
                </li>
              </ul>
            </div>
            <div className="px-6">
              <h1 className="text-xl">Contact Us</h1>
              <p>info@dewebgineer.com</p>
            </div>
          </div>
        </div>
        <div className="text-center px-3">
          Copyright © {new Date().getFullYear()}
          <strong> Dewebgineer Solutions</strong>
        </div>
      </footer>
    </div>
  );
};

export default Layout;

/**
 * 
<link href="/fonts.css" rel="stylesheet" />
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap"
          rel="stylesheet"
        /> 
        <link rel="icon" type="image/png" href="favicon.ico" />
        <link rel="apple-touch-icon" href="favicon.ico" />

<meta property="og:url" content={`${props.canonical}`} />  

<link rel="icon" type="image/png" href="/static/images/favicon.ico" />
<link rel="apple-touch-icon" href="/static/images/favicon.ico" />
 */
