/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Link } from 'react-scroll';
import Logo from '../svgs/logo.svg';
import { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="bg-white fixed right-0 left-0 z-50">
      <div className="max-w-screen-lg mx-auto py-1 px-5 flex justify-between items-center ">
        <div>
          <GatsbyLink to="/" title="home">
            <Logo className="h-14 w-32" />
          </GatsbyLink>
        </div>
        <div className="md:hidden relative">
          <button aria-label="Menu" onClick={handleBurgerClick}>
            <svg
              className={`burger ${isOpen && 'burger--open'}`}
              viewBox="0 0 100 80"
              width="25"
              height="25"
              fill="#000"
            >
              <rect className="burger-first-line" width="80" height="10"></rect>
              <rect
                className="burger-second-line"
                y="30"
                width="100"
                height="10"
              ></rect>
              <rect
                className="burger-third-line"
                y="60"
                width="80"
                height="10"
              ></rect>
            </svg>
          </button>
        </div>
        <ul className="text-black hidden md:flex">
          <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="home"
              smooth={true}
              spy={true}
            >
              Home
            </Link>
          </li>
          <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="services"
              smooth={true}
              spy={true}
            >
              Our Services
            </Link>
          </li>
          <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="about"
              smooth={true}
              spy={true}
            >
              About Us
            </Link>
          </li>
          <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="portfolio"
              smooth={true}
              spy={true}
            >
              Our Portfolio
            </Link>
          </li>
          {/* <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="testimonials"
              smooth={true}
              spy={true}
            >
              Testimonials
            </Link>
          </li> */}
          <li className="mx-3">
            <Link
              href="#"
              className="text-lg text-indigo-dye hover:text-celadon-blue transition-all duration-300"
              activeClass="text-celadon-blue font-bold"
              to="contact"
              smooth={true}
              spy={true}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div
        className={`mobile-menu md:hidden py-3 ${
          isOpen && 'mobile-menu--show'
        }`}
      >
        <ul
          className="flex flex-col text-center text-white-alt"
          onKeyDown={closeMenu}
          role="menu"
        >
          <li role="menuitem">
            <Link
              href="#"
              to="home"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              Home
            </Link>
          </li>
          <li role="menuitem">
            <Link
              href="#"
              to="services"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              Our Services
            </Link>
          </li>
          <li role="menuitem">
            <Link
              href="#"
              to="about"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              About Us
            </Link>
          </li>
          <li role="menuitem">
            <Link
              href="#"
              to="portfolio"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              Our Portfolio
            </Link>
          </li>
          <li role="menuitem">
            <Link
              href="#"
              to="testimonials"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              Testimonials
            </Link>
          </li>
          <li role="menuitem">
            <Link
              href="#"
              to="contact"
              smooth={true}
              className="block py-3"
              activeClass="bg-indigo-dye"
              spy={true}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
